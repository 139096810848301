<template>
    <div class="home">
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

        <!-- tabs -->
        <div class="tabs" style="user-select: none;">
            <div class="tab" :style="{ width: tabsList.length * 126 + 'px' }">
                <!-- :style="{ borderLeft: index == 1 || index == 3 ? '1px solid #e0e0e0' : '', borderRight: index == 1 || index == 3 ? '1px solid #e0e0e0' : '' }" -->
                <div class="son" :class="[i.act ? 'tabsAct' : '']" @click="tabCilck(index, i)"
                    :style="{ borderLeft: index != 0  ? '1px solid #e0e0e0' : ''}"
                    v-for="(i, index) in tabsList" :key="index">{{ i.typeName }}</div>
            </div>
        </div>

        <!-- 数控列表 -->
        <div class="cncList" v-loading="loading">
            <div class="son" v-for="(i, index) in CNCinfoList" :key="index" @click="spuClick(i)">
                <div class="lei">{{ i.brandName }}</div>
                <img :src="i.img.split(',')[0]" alt="" style="margin-right: 10px;">
                <div class="r">
                    <div class="name">{{ i.title }}</div>
                    <div class="tab">{{ i.exemptionFromPostage == 'BY' ? '包邮' : i.exemptionFromPostage == 'BBY' ? '不包邮' :
                        '满额包邮' }}</div>
                    <div class="progress">
                        <!-- <div style="width: 120px;">
                            <el-progress :percentage="percentage" :stroke-width="12" color="#FB3541"
                                define-back-color="#FFE3CA" :show-text="false"></el-progress>
                        </div> -->
                        <span>已抢{{ i.sales }}件</span>
                    </div>
                    <div class="jiage">
                        <div class="nub">￥<span>{{ i.price == 0 ? '面议' : i.price }}</span></div>
                        <div class="yuanj">￥{{ i.price2 }} </div>
                    </div>
                </div>
            </div>
            <div v-if="CNCinfoList.length == 0"
                style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                <img src="@/assets/wsj.png" style="width: 250px;object-fit: contain;" alt="">
                <div style="width: 100%;margin-top: 10px;">暂无数据</div>
            </div>
        </div>

        <!-- 分页 -->
        <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="paging.pageSize"></paging>


        <!-- 底部 -->
        <!-- <bottomContent></bottomContent> -->
    </div>
</template>

<script>
import { getCNCMustGrab, getCNCMustGrabRob, getCNCMustGrabInfo } from "@/utils/api/homeApi/index"
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import paging from "@/components/paging.vue";
export default {
    name: 'CNCMustGrab',
    components: {
        largeHead,
        bottomContent,
        paging
    },
    data() {
        return {
            loading: true,
            // 头部激活菜单
            tabArrShow: 5,
            tabsList: [],
            // 进度条
            percentage: 60,
            paging: {
                pageSize: 15,
                currentPage: 1,
                total: 0,
                categoryId: ''
            },
            CNCinfoList: []
        };
    },
    mounted() {
        this.getCNCMustGrabInfo()

    },
    methods: {
        tabCilck(index, i) {
            this.loading = true
            this.tabsList.map((t) => (t.act = false));
            this.tabsList[index].act = true;
            this.paging.categoryId = i.id
            this.getCNCInfo({
                pageSize: this.paging.pageSize,
                currentPage: this.paging.currentPage,
                categoryId: i.id
            })
        },
        // 跳转事件
        spuClick(i) {
            window.open(`#/commodity/productDetails?ids=${i.spuId}`, '_blank');
        },
        // 分页
        handleCurrentChange(val) {
            this.loading = true
            console.log(val)
            this.paging.currentPage = val
            this.getCNCInfo({
                pageSize: this.paging.pageSize,
                currentPage: this.paging.currentPage,
                categoryId: this.paging.categoryId
            })
        },
        // 请求数控必抢数据
        async getCNCMustGrabInfo() {
            let tabArr = [];
            // const res = await getCNCMustGrab();
            // if (res.statusCode == 8201) {
            //     const promises = res.data.map(async (d) => {
            //         if (d.itemId) {
            //             const rej = await getCNCMustGrabRob(d.itemId);
            //             if (rej.statusCode == 8201) {
            //                 rej.data.map((b, index) => {
            //                     this.$set(b, 'act', false);
            //                     tabArr.push(b);
            //                     tabArr[0].act = true;
            //                 });
            //             }
            //         }
            //     });

            //     await Promise.all(promises);

            //     this.tabsList = tabArr;
            //     console.log(tabArr, 312);
            //     this.getCNCInfo({
            //         pageSize: this.paging.pageSize,
            //         currentPage: this.paging.currentPage,
            //         categoryId: this.tabsList[0].id
            //     })
            // }

            const rej = await getCNCMustGrabRob('');
            if (rej.statusCode == 8201) {
                rej.data.map((b, index) => {
                    this.$set(b, 'act', false);
                    tabArr.push(b);
                    tabArr[0].act = true;
                    this.tabsList = tabArr;
                    this.getCNCInfo({
                        pageSize: this.paging.pageSize,
                        currentPage: this.paging.currentPage,
                        categoryId: tabArr[0].id
                    })
                })
            }
        },
        // 请求商品数据
        async getCNCInfo(data) {
            const res = await getCNCMustGrabInfo(data)
            if (res.statusCode == 8201) {
                this.CNCinfoList = res.data.records
                this.paging.total = res.data.total
                this.loading = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    // tab
    .tabs {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        overflow-x: auto;

        >.tab {
            display: flex;
            border: 1px solid #E0E0E0;
            background-color: #fff;
            box-sizing: border-box;
            color: #333;


            >.son {
                width: 126px;
                height: 30px;
                box-sizing: border-box;
                // border: 1px solid #fff;
                text-align: center;
                line-height: 30px;
                font-size: 14px;
                text-align: center;
                transition: all .3s;
                cursor: pointer;
            }
        }

    }

    // 数控列表
    >.cncList {
        width: 1200px;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        margin: 30px auto;
        gap: 20px;


        >.son {
            width: 380px;
            height: 150px;
            border: 1px solid #E0E0E0;
            border-radius: 4px;
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-content: center;
            position: relative;
            cursor: pointer;
            background-color: #fff;
            margin-bottom: 20px;

            >.lei {
                position: absolute;
                top: 0;
                right: 0;
                width: auto;
                height: 18px;
                border-radius: 0px 1px 0px 9px;
                background-color: #CEF0FF;
                border: 1px solid #80C9FF;
                text-align: center;
                line-height: 18px;
                color: #387FEA;
                font-size: 12px;
                padding: 0 5px;
                box-sizing: border-box;
            }

            >img {
                width: 130px;
                height: 130px;
            }

            >.r {
                width: 220px;
                height: 130px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-top: 5px;

                >.name {
                    width: 220px;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                >.tab {
                    width: 59px;
                    height: 18px;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 18px;
                    color: #E26D00;
                    background-color: #FFF7D9;
                    border: 1px solid #FFBE82;
                    font-size: 12px;
                }

                >.progress {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    >span {
                        font-size: 12px;
                        color: #999;
                        margin-left: 5px;
                    }
                }

                >.jiage {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    >.nub {
                        width: 78px;
                        height: 36px;
                        text-align: center;
                        line-height: 25px;
                        color: #fff;
                        background: url(../assets/icon/skbqjgbg.png);
                        background-size: 100% 100%;
                        font-size: 12px;

                        >span {
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }

                    >.yuanj {
                        font-size: 12px;
                        color: #999;
                        text-decoration: line-through;
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    // 分页
    .paging {
        width: 1200px;
        height: 50px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
    }
}

.tabsAct {
    background-color: #FF4242;
    color: #fff;
    transition: all .3s;
}

// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/* 设置水平滚动条的样式 */
::-webkit-scrollbar {
    height: 6px;
    /* 设置滚动条的高度 */
}

/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /* 设置滚动槽的阴影效果 */
    border-radius: 10px;
    /* 设置滚动槽的圆角 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* 设置滚动条滑块的圆角 */
    background: rgba(0, 0, 0, 0.1);
    /* 设置滚动条滑块的背景颜色 */
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    /* 设置滚动条滑块的阴影效果 */
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #d9d9d9;
    /* 设置非活动状态下滚动条滑块的背景颜色 */
}
</style>